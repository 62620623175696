/* eslint-disable @typescript-eslint/no-explicit-any */
import { reaction, runInAction, when } from 'mobx'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { SliceDto, ThreadDto, TraceDataDto } from 'components/ps-chart/models/TraceDataDto'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { toastWithTitle } from 'components/StyledToaster'
import { useToaster } from 'hooks/useToaster'
import { ChartDataStore } from 'components/ps-chart/stores/ChartDataStore'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { useApi } from 'contexts/di-context'
import { ChartPageParams, NamedLinkDto, NamedLinkType } from 'api/models'
import { TableSummaryView } from 'components/cco/TableStateToggleButton'

import { TimelineView } from 'components/cco/TimelineView'
import { StatsView } from 'components/cco/StatsView'
import {
  LogItem,
  ChartData,
  SummaryDto,
  MethodDto,
  MethodData,
  SessionEndpointSummaryDto,
  SessionData,
  StatisticDataDto,
  DashboardData,
  EndpointData,
  MethodType,
} from 'components/cco/types'
import { useCcoParams } from 'components/cco/useCcoParams'
import { useHotKeys } from 'components/ps-chart/hooks/useHotKeys'

export { PATH_CCO_CHART, PATH_CCO_SNAPSHOT, PATH_CCO_DEMO } from 'components/cco/useCcoParams'

export const ECLIPSE_METHOD_PATTERN = /^org\/eclipse\/jetty/

// parses blob data into TraceDataDto
const reader = new FileReader()

function sumSummaries(summaryA: SummaryDto, summaryB: SummaryDto): SummaryDto {
  return {
    cpuTime: summaryA.cpuTime + summaryB.cpuTime,
    traffic: summaryA.traffic + summaryB.traffic,
    storage: summaryA.storage + summaryB.storage,
    count: summaryA.count + summaryB.count,
  }
}

function defaultSortUsingSummary(
  { cpuTime: cpuTimeX }: SummaryDto,
  { cpuTime: cpuTimeY }: SummaryDto,
): number {
  return cpuTimeY - cpuTimeX // Descending order
}

const namedLinks: NamedLinkDto[] = [
  {
    id: '0',
    toName: ' <init>',
    fromName: ' call',
    type: NamedLinkType.OBJECT,
    isEditable: false,
  },
  {
    id: '1',
    toName: ' <init>',
    fromName: ' run',
    type: NamedLinkType.OBJECT,
    isEditable: false,
  },
  {
    id: '2',
    toName: ' <SCHEDULE>',
    fromName: ' call',
    toId: 'runnableId',
    fromId: 'runnableId',
    type: NamedLinkType.COMPLEX,
    isEditable: false,
  },
  {
    id: '3',
    toName: ' <SCHEDULE>',
    fromName: ' run',
    toId: 'runnableId',
    fromId: 'runnableId',
    type: NamedLinkType.COMPLEX,
    isEditable: false,
  },
  {
    id: '4',
    toName: ' <init>',
    fromName: ' @future',
    toId: 'objectId',
    fromId: 'futureId',
    type: NamedLinkType.COMPLEX,
    isEditable: false,
  },
  {
    id: '5',
    toName: ' @future',
    fromName: ' @future',
    toId: 'futureId',
    fromId: 'futureId',
    type: NamedLinkType.COMPLEX,
    isEditable: false,
  },
  {
    id: '6',
    toName: ' completeResponse',
    fromName: 'ManagedCompletableFuture ',
    toId: 'objectId',
    fromId: 'futureId',
    type: NamedLinkType.COMPLEX,
    isEditable: false,
  },
]

function repositionSlices(slices: SliceDto[]) {
  for (let i = 0; i < slices.length; i++) {
    const outerSlice = slices[i]
    for (let j = i + 1; j < slices.length; j++) {
      const innerSlice = slices[j]
      if (innerSlice.start >= outerSlice.start && innerSlice.end <= outerSlice.end) {
        slices.splice(j, 1)
        j--
        outerSlice.children.push(innerSlice)
      } else if (innerSlice.start > outerSlice.end) {
        break
      }
    }
    repositionSlices(outerSlice.children)
  }
}

function parseLogItems(logItems: string[]): LogItem {
  const logItem: LogItem = {}

  for (const keyValuePair of logItems) {
    const [key, value] = keyValuePair.split('=')
    switch (key) {
      case 'principal':
        logItem.principal = value
        break
      case 'access_token':
        logItem.accessToken = value
        break
      case 'runnable':
        logItem.runnable = Number(value)
        break
      case 'callable':
        logItem.callable = Number(value)
        break
      case 'objId':
        logItem.objectId = Number(value)
        break
      // ... add more cases for other keys you might need
    }
  }

  return logItem
}

const filterMethodDta = (methodDto: MethodDto): boolean => {
  const { method } = methodDto
  return !ECLIPSE_METHOD_PATTERN.test(method)
}

const parseMethodDta =
  (type: MethodType) =>
  (methodDto: MethodDto): MethodData => {
    const { method, summary } = methodDto
    return { title: method, ...summary, type }
  }

function groupSessions(rawSessionEndpoints: SessionEndpointSummaryDto[]): SessionData[] {
  const sessionMap = rawSessionEndpoints.reduce((acc, sessionEndpointDto) => {
    const {
      userSession,
      start,
      end,
      endpoint,
      directSummary,
      indirectSummary,
      directMethods,
      indirectMethods,
    } = sessionEndpointDto

    if (!acc[userSession]) {
      acc[userSession] = {
        title: userSession,
        start: start,
        end: end,
        ...sumSummaries(directSummary, indirectSummary),
        endpoints: [
          {
            title: endpoint,
            start: start,
            end: end,
            directMethods: directMethods
              .filter(filterMethodDta)
              .map(parseMethodDta(MethodType.DIRECT)),
            indirectMethods: indirectMethods
              .filter(filterMethodDta)
              .map(parseMethodDta(MethodType.INDIRECT)),
            ...sumSummaries(directSummary, indirectSummary),
          },
        ],
      }
    } else {
      const existingSession = acc[userSession]
      const {
        title: sessionTitle,
        start: sessionStart,
        end: sessionEnd,
        endpoints: sessionEndpoints,
        ...summary
      } = existingSession

      sessionEndpoints.push({
        title: endpoint,
        start: start,
        end: end,
        directMethods: directMethods.filter(filterMethodDta).map(parseMethodDta(MethodType.DIRECT)),
        indirectMethods: indirectMethods
          .filter(filterMethodDta)
          .map(parseMethodDta(MethodType.INDIRECT)),
        ...sumSummaries(directSummary, indirectSummary),
      })

      acc[userSession] = {
        title: sessionTitle,
        start: Math.min(sessionStart, start),
        end: Math.max(sessionEnd, end),
        endpoints: sessionEndpoints,
        ...sumSummaries({ ...summary }, sumSummaries(directSummary, indirectSummary)),
      }
    }

    return acc
  }, {} as Record<string, SessionData>)

  return Object.values(sessionMap)
}

function parseToDashboard(data: StatisticDataDto): DashboardData {
  const chartsData: ChartData[] = []
  data.graphData.timeStamps.forEach((time, index) => {
    chartsData.push({
      x: time,
      yCPU: data.graphData.cpuPoints[index],
      yTraffic: data.graphData.trafficPoints[index],
      yStorage: data.graphData.storagePoints[index],
      yActivity: data.graphData.activityPoints[index],
    })
  })

  const sessionsData: SessionData[] = []
  if (data.sessionSummaries && data.sessionSummaries.length) {
    sessionsData.push(...groupSessions(data.sessionSummaries))
  }

  const endpointsData: EndpointData[] = []
  if (data.entryPointsSummary && data.entryPointsSummary.length) {
    for (const entryPoint of data.entryPointsSummary) {
      const { endpoint, directSummary, directMethods, indirectSummary, indirectMethods } =
        entryPoint
      const sessions = Object.entries(
        data.sessionSummaries
          .filter((sessionEntrypoint) => sessionEntrypoint.endpoint === entryPoint.endpoint)
          .reduce(
            (
              acc: Record<string, { directSummary: SummaryDto; indirectSummary: SummaryDto }>,
              sessionEntrypoint,
            ) => {
              const {
                userSession,
                directSummary: sessionDirectSummary,
                indirectSummary: sessionIndirectSummary,
              } = sessionEntrypoint

              if (!acc[userSession]) {
                acc[userSession] = {
                  directSummary: { ...sessionDirectSummary },
                  indirectSummary: { ...sessionIndirectSummary },
                }
              } else {
                acc[userSession].directSummary = sumSummaries(
                  acc[userSession].directSummary,
                  sessionDirectSummary,
                )
                acc[userSession].indirectSummary = sumSummaries(
                  acc[userSession].indirectSummary,
                  sessionIndirectSummary,
                )
              }

              return acc
            },
            {},
          ),
      )
        .map(
          ([userSession, summaries]: [
            string,
            { directSummary: SummaryDto; indirectSummary: SummaryDto },
          ]) => ({
            title: userSession,
            ...sumSummaries(summaries.directSummary, summaries.indirectSummary),
          }),
        )
        .sort(defaultSortUsingSummary)
      const endpointData: EndpointData = {
        title: endpoint,
        ...sumSummaries(directSummary, indirectSummary),
        directMethods: directMethods.filter(filterMethodDta).map(parseMethodDta(MethodType.DIRECT)),
        indirectMethods: indirectMethods
          .filter(filterMethodDta)
          .map(parseMethodDta(MethodType.INDIRECT)),
        sessions: sessions,
      }
      endpointsData.push(endpointData)
    }
  }

  return { chartsData, endpointsData, sessionsData }
}

function transformToTimelineData(originalData: any): TraceDataDto {
  const threads: ThreadDto[] = []
  let threadIdCounter = 0
  let globalSliceIdCounter = 1

  // Group records by threadName
  const recordsByThreadName = originalData.usageRecords?.reduce(
    (acc: { [x: string]: any[] }, record: { threadName: any }) => {
      const { threadName } = record
      if (!acc[threadName]) {
        acc[threadName] = []
      }
      acc[threadName].push(record)
      return acc
    },
    {} as Record<string, any[]>,
  )

  let minStartTime = Infinity
  for (const threadName in recordsByThreadName) {
    const records = recordsByThreadName[threadName]
    const firstRecord = records[0]
    if (firstRecord) {
      minStartTime = Math.min(minStartTime, firstRecord.callsStart[0])
    }
  }

  // Process each thread
  for (const threadName in recordsByThreadName) {
    if (threadName !== 'default-nioEventLoopGroup-1-5') {
      //continue
    }
    const records = recordsByThreadName[threadName]
    const thread: ThreadDto = {
      id: threadIdCounter++,
      name: threadName,
      slices: [],
      isAsync: false,
    }

    // Create initial slices
    for (const record of records) {
      for (const [index, startTime] of record.callsStart.entries()) {
        const endTime = record.callsEnd[index]
        const logItems = parseLogItems(record.logItems[index])

        const objectId = logItems.objectId ?? null
        let scheduleId = null
        const callableId = logItems.callable ?? null
        const runnableId = logItems.runnable ?? null
        const regionType = record.region.type
        let name = ''
        let args: { key: string; value: string }[] = []
        if (regionType === 'EndpointDto') {
          const { endpoint, httpMethod, method } = record.region
          const { className, methodName, methodDesc } = method
          name = `${regionType} ${endpoint}`
          args = [
            { key: '00-endpointDetails.00-url', value: endpoint },
            { key: '00-endpointDetails.01-httpMethod', value: httpMethod },
            { key: '01-methodDetails.00-className', value: className },
            { key: '01-methodDetails.01-name', value: methodName },
            { key: '01-methodDetails.02-desc', value: methodDesc },
          ]
        } else if (regionType === 'MethodDto') {
          const { className, methodName, methodDesc } = record.region
          const parts = className.split('/')
          const lastPart = parts.length > 0 ? parts[parts.length - 1] : className

          name = `${lastPart} ${methodName}`

          args = [
            { key: '00-methodDetails.00-className', value: className },
            { key: '00-methodDetails.01-name', value: methodName },
            { key: '00-methodDetails.02-desc', value: methodDesc },
          ]
        }
        if (runnableId !== null || callableId !== null) {
          name = `${name} <SCHEDULE>`
          args.push({ key: 'scheduleID', value: String(runnableId ?? callableId) })
          scheduleId = runnableId ?? callableId
        }

        thread.slices.push({
          id: globalSliceIdCounter++,
          closureId: null,
          objectId: objectId,
          runnableId: scheduleId,
          name: name,
          start: startTime - minStartTime,
          end: endTime - minStartTime,
          children: [],
          arguments: args,
        })
      }
    }
    thread.slices.sort((a, b) => a.start - b.start)
    repositionSlices(thread.slices)
    threads.push(thread)
  }

  return { threads, metadata: { totalTime: 0 } }
}

export const CCOPage = () => {
  const { projectUrlName, sessionId, showTrace, apiUrl, apiMode, snapshotId } = useCcoParams()

  const [appID, setAppID] = useState<null | string>(null)
  const [dashDataDto, setDashDataDto] = useState<null | any>(null)
  const [rawDataDto, setRawDataDto] = useState<null | any>(null)
  const [sessionData, setSessionData] = useState<SessionData | null>(null)
  const [dashboardData, setDashboardData] = useState<DashboardData>({
    chartsData: [],
    endpointsData: [],
    sessionsData: [],
  })

  const isSnapshotLoaded = snapshotId !== undefined

  const tracePageParams: ChartPageParams = useMemo(() => {
    return {
      flowProjectLocalId: '1', // currently there is no flow logic in CCO - all snapshots are stored in same flow id 1
      projectUrlName,
      traceProjectLocalId: snapshotId !== undefined ? snapshotId : 'none',
    }
  }, [projectUrlName, snapshotId])

  const [searchValue, setSearchValue] = useState<string>('')
  const [summaryViewMode, setSummaryViewMode] = useState<TableSummaryView>(TableSummaryView.TOTAL)
  const [traceViewMode, setTraceViewMode] = useState<boolean>(false)

  const api = useApi()
  const toaster = useToaster()

  const chartDataStore = useMemo(
    () => new ChartDataStore(api, tracePageParams, new PsChartSettings()),
    [api, tracePageParams],
  )
  if (chartDataStore == null) {
    throw new Error('ChartDataStore has not been found')
  }

  const psChartStore: PsChartStore = useMemo(() => {
    return new PsChartStore(
      chartDataStore,
      chartDataStore.settings,
      api,
      tracePageParams,
      toaster,
      chartDataStore.traceDataStore,
      chartDataStore.hStateStore,
      chartDataStore.videoDataStore,
      chartDataStore.annotationsDataStore,
      chartDataStore.flagsDataStore,
      false,
      !isSnapshotLoaded,
      true,
    )
  }, [chartDataStore, api, tracePageParams, toaster, isSnapshotLoaded])

  useHotKeys(
    ['KeyH'],
    () => psChartStore.flagsStore.toggleShowLabels(),
    psChartStore.isEnabledListeners,
  )

  useHotKeys(['KeyG'], () => psChartStore.goToSelectedSlice(), psChartStore.isEnabledListeners)

  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(
    () =>
      when(
        () => psChartStore.isLoaded,
        () => {
          const locationState = location.state
          if (
            locationState == null ||
            locationState.sliceId == null ||
            locationState.title == null
          ) {
            return null
          }
          const sliceId = Number(locationState.sliceId)
          const slice = psChartStore.sliceById.get(sliceId)
          if (slice == null) {
            console.warn(`The slice with ID ${sliceId} has not been found!`)
            return null
          }
          if (!locationState.title.includes(slice.title)) {
            console.warn(
              `The slice with ID ${sliceId} has a wrong title. Expected to include: "${locationState.title}" / Actual: "${slice.title}"`,
            )
            return null
          }
          psChartStore.setSelectedSlice(slice)
        },
      ),
    [psChartStore, location],
  )

  const updateView = useCallback(
    (params: URLSearchParams) => {
      const zoom = params.get('zoom')
      if (zoom) {
        const [xStart, xEnd, yStart] = zoom.split('-').map((value) => parseInt(value, 10))
        if (xStart !== undefined && xEnd !== undefined && yStart !== undefined) {
          psChartStore.hState.setXStartAndXEnd(xStart, xEnd)
        }
      }

      const linkType = params.get('linkType')
      if (linkType) {
        const isForwardLinkType = params.get('linkType') === 'FRWD'
        if (isForwardLinkType) {
          psChartStore.traceAnalyzeStore.toggleForwardDirection()
        } else {
          psChartStore.traceAnalyzeStore.toggleBackwardDirection()
        }
      }
    },
    [psChartStore],
  )

  useEffect(() => {
    if (psChartStore.isLoaded) {
      const { hash } = location
      updateView(searchParams)
      const currentSliceId = psChartStore.traceAnalyzeStore.selectedSlice?.id
      if (hash) {
        const hashSliceId = Number(hash.slice(1))
        if (!isNaN(hashSliceId)) {
          const slice = psChartStore.sliceById.get(hashSliceId)
          if (slice && psChartStore.traceAnalyzeStore.selectedSlice !== slice) {
            psChartStore.setSelectedSlice(slice)
          }
        }
      } else if (!currentSliceId) {
        psChartStore.setSelectedSlice(null)
      }
    }
  }, [psChartStore, location, psChartStore.isLoaded, updateView, searchParams])

  useEffect(() => {
    const dispose = reaction(
      () => [psChartStore.traceAnalyzeStore.selectedSlice],
      () => {
        if (psChartStore.isLoaded) {
          const sliceId = psChartStore.traceAnalyzeStore.selectedSlice?.id
          const newHash = `#${sliceId}`
          if (sliceId && location.hash !== newHash) {
            navigate(newHash, { preventScrollReset: true })
          } else if (!sliceId && location.hash) {
            navigate(location.pathname, { preventScrollReset: true })
          }
        }
      },
    )
    return () => {
      dispose()
    }
  }, [location, navigate, psChartStore.isLoaded, psChartStore.traceAnalyzeStore.selectedSlice])

  useEffect(() => {
    if (snapshotId) {
      api
        .getTrace({
          projectUrlName,
          // @ts-ignore
          flowProjectLocalId: '1',
          traceProjectLocalId: snapshotId,
        })
        .then((trace) => {
          // @ts-ignore
          setDashDataDto(trace.summaryData)
          // @ts-ignore
          setRawDataDto(trace.traceData)
        })
    } else {
      fetch(apiUrl('sessions'))
        .then((response) => response.json())
        .then((sessions) => {
          setAppID(sessions[0].id)
        })
        .catch((error) => console.error('Error fetching data:', error))
    }
  }, [apiUrl, snapshotId, api, projectUrlName])

  const fetchDashboardData = useCallback(() => {
    if (appID !== null) {
      fetch(apiUrl(`applications/${appID}/state-summary/${apiMode}`))
        .then((response) => response.json())
        .then((incomingData) => setDashDataDto(incomingData))
        .catch((error) => console.error('Error fetching data:', error))
    }
  }, [apiUrl, apiMode, appID])

  const fetchTraceData = useCallback(() => {
    if (appID !== null) {
      fetch(apiUrl(`applications/${appID}/state-trace/${apiMode}`))
        .then((response) => response.json())
        .then((incomingTrace) => setRawDataDto(incomingTrace))
        .catch((error) => console.error('Error fetching data:', error))
    }
  }, [apiUrl, apiMode, appID])

  useEffect(() => {
    // Fetch data initially
    fetchDashboardData()
    fetchTraceData()

    let interval: NodeJS.Timeout | number | undefined

    // Only set up the interval if apiMode is not "static"
    if (apiMode !== 'static') {
      interval = setInterval(() => {
        if (!traceViewMode) {
          fetchDashboardData()
          fetchTraceData()
        }
      }, 10000)
    }

    // Clear the interval when the component unmounts or apiMode changes
    return () => {
      if (interval !== undefined) {
        clearInterval(interval as NodeJS.Timeout)
      }
    }
  }, [appID, apiMode, traceViewMode, fetchDashboardData, fetchTraceData])

  useEffect(() => {
    psChartStore.chartSettings.addEventListeners()
    return () => psChartStore.chartSettings.removeEventListeners()
  }, [psChartStore])

  useEffect(() => {
    if (dashDataDto != null) {
      runInAction(() => {
        setDashboardData(parseToDashboard(dashDataDto))
      })
    }
  }, [dashDataDto])

  useEffect(() => {
    if (rawDataDto != null) {
      runInAction(() => {
        const timelineData =
          rawDataDto.threads !== undefined ? rawDataDto : transformToTimelineData(rawDataDto)

        chartDataStore.traceDataStore.process(timelineData)
        psChartStore.hState.setXStartAndXEnd(psChartStore.hState.xMin, psChartStore.hState.xMax)
      })
      psChartStore.reloadConnections(namedLinks)
      if (isSnapshotLoaded) {
        psChartStore.reloadFlags().catch((reason) => {
          return Promise.reject(reason)
        })
      }
    }
  }, [rawDataDto, psChartStore, chartDataStore.traceDataStore, apiMode, isSnapshotLoaded])

  const processFile = (fileEvent: ChangeEvent<HTMLInputElement>) => {
    try {
      if (fileEvent?.target?.files) {
        reader.onload = (readEvent: ProgressEvent<FileReader>) => {
          if (readEvent.target?.result) {
            const fileContents = JSON.parse(String(readEvent.target.result))
            return setDashDataDto(fileContents)
          }
        }
        return reader.readAsText(fileEvent.target.files[0])
      }
    } catch (e) {
      console.error(e)
      toast.error(
        toastWithTitle(
          'Error processing trace file',
          'Could not render flame chart, please try a new file.',
          true,
        ),
      )
    }
  }

  useEffect(() => {
    if (sessionId) {
      const selectedSession = dashboardData.sessionsData.find(({ title }) => title === sessionId)
      if (selectedSession !== undefined) {
        setSessionData(selectedSession)
        psChartStore.hState.setXStartAndXEnd(selectedSession.start, selectedSession.end)
      }
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    } else {
      setSessionData(null)
    }
  }, [dashboardData, psChartStore.hState, sessionId])

  useEffect(() => {
    // Switch to traces when showTraces route path is `traces`
    setTraceViewMode(showTrace)
  }, [showTrace])

  const handleTableSummaryViewChange = (view: TableSummaryView) => {
    setSummaryViewMode(view)
  }

  const handleSearchChange = (input: string) => {
    setSearchValue(input)
  }

  if (!projectUrlName) {
    return null
  }

  return (
    <>
      {traceViewMode ? (
        <TimelineView
          sessions={dashboardData.sessionsData}
          sessionData={sessionData}
          psChartStore={psChartStore}
          traceData={rawDataDto}
          summaryData={dashDataDto}
        />
      ) : (
        <StatsView
          dashboardData={dashboardData}
          sessionData={sessionData}
          onSummaryViewChange={handleTableSummaryViewChange}
          onSearchChange={handleSearchChange}
          search={searchValue}
          summaryViewMode={summaryViewMode}
          processFile={processFile}
          psChartStore={psChartStore}
        />
      )}
    </>
  )
}
