import {
  CustomErrorDto,
  FlowDto,
  MetricsDto as MetricsDtoGenerated,
  SigninSuccessDto,
  SupportIssueCategoryValue,
  TeamDto,
  TraceDto,
  InstructionsStateDto,
} from './__generated__/api-types'

import type { components, operations } from './__generated__/api-base'

export {
  AnnotationDelay,
  AppStartType,
  BuildType,
  ComputationStatus,
  FreeTrialStage,
  OsType,
  ProjectBuildStatusOut,
  ProjectRoleApi,
  SupportIssueCategory,
  TechType,
  TeamRoleApi,
  TokenDest,
  TraceProcessingErrorCode,
  TraceProcessingState,
  TraceQuality,
  VideoProcessingStateDto,
  ProjectDtoType,
} from 'api/__generated__/api-constants'

export * from './__generated__/api-types'

export type SsoSignupRequireInfoResponse = components['schemas']['SsoSignupRequireInfoResponse']

export type SignInResDto = SigninSuccessDto | SsoSignupRequireInfoResponse

export type InviteWhitelistReq =
  operations['inviteWhitelisted']['requestBody']['content']['application/json']

export type TeamsArray = Array<TeamDto>

export interface TeamReq {
  teamUrlName?: string
}

export interface UserIdReq {
  userId: number
}

export interface ProjectReq {
  projectUrlName?: string
}

export interface FlowReq extends ProjectReq {
  flowProjectLocalId?: string
}

export type Flows = Array<FlowDto>

export type Trace = TraceDto

export type Traces = Array<Trace>

export interface TraceReq extends ProjectReq {
  traceProjectLocalId?: string
}

export interface FlowTraceReq extends FlowReq {
  traceProjectLocalId?: string
}

export type ApiError = CustomErrorDto

export type DeleteTraceLocalFlagDto =
  operations['deleteFlag']['requestBody']['content']['application/json']

export type TracePageParams = Required<TraceReq>

export type ChartPageParams = TracePageParams & {
  flowProjectLocalId: string
}

// TODO: Generated TraceVideoMetadataDto.processingErrorCode is string. Talk to BE to make it a enum.
export enum ProcessingErrorCode {
  VIDEO_FRAMES_NOT_FOUND = 'trace.video.videoFramesNotFound',
  TRACE_VIDEO_METADATA_NOT_FOUND = 'trace.video.traceVideoMetadataNotFound',
  INVALID_VIDEO = 'trace.video.invalidVideo',
  TIMEOUT = 'trace.video.timeout',
  INTERNAL = 'trace.video.internal',
  DEFAULT = 'default',
}

export interface ChoreographerReq {
  projectUrlName: string
  traceProjectLocalId: string
}

export type InstrumentStepKey = keyof InstructionsStateDto

export interface SliceSuggestionsReq {
  projectUrlName: string
  flowProjectLocalId: string
  traceProjectLocalId: string
}

export interface FlowComputationReq {
  projectUrlName: string
  flowProjectLocalId: string
}

export interface ApkSupportIssueReq {
  category: SupportIssueCategoryValue
  description: string
  subject: string
  projectName?: string
  executionLog?: string
  apkFile?: File // binary
}

export interface PostTraceEntity {
  projectIdOrUrlName: string
  flowProjectLocalId: string
}

export enum MetricsName {
  totalTraceTimeToReady = 'totalTraceTimeToReady',
  traceConnectionsTime = 'traceConnectionsTime',
}

export enum MetricsMethodTag {
  frontendConnections = 'frontendConnections',
  backendConnections = 'backendConnections',
}

export interface MetricsDto extends MetricsDtoGenerated {
  name: MetricsName
  tags: {
    method: MetricsMethodTag
  }
}

/**
 * Overriden NamedLinkType with added RUNNABLE
 * TODO: figure out why we don't have it in our API schema
 * */
export const NamedLinkType = {
  SYNC: 'SYNC',
  ASYNC: 'ASYNC',
  OBJECT: 'OBJECT',
  DIRECT: 'DIRECT',
  COMPLEX: 'COMPLEX',
} as const

export type NamedLinkTypeValue = typeof NamedLinkType[keyof typeof NamedLinkType]

export type NamedLinkIdType = 'objectId' | 'runnableId' | 'futureId'

/**
 * Overriden NamedLinkDto with added RUNNABLE NamedLinkType
 * TODO: figure out why we don't have it in our API schema
 * */
export type NamedLinkDto = {
  id: string
  fromName: string
  toName: string
  toId?: NamedLinkIdType
  fromId?: NamedLinkIdType
  type: NamedLinkTypeValue
  isEditable: boolean
}

/**
 * Overriden CreateNamedLinkDto with added RUNNABLE NamedLinkType
 * TODO: figure out why we don't have it in our API schema
 * */
export type CreateNamedLinkDto = {
  fromName: string
  toName: string
  type: NamedLinkTypeValue
  isEditable?: boolean | null
}
