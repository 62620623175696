import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { NamedLinkDto, NamedLinkIdType, NamedLinkType, NamedLinkTypeValue } from 'api/models'
import { getConnectionError } from 'components/ps-chart/stores/connections-store/getConnectionError'
import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { OBJECT_ID_EMPTY_VALUE, Slice } from 'components/ps-chart/models/Slice'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'
import { ConnectionDirection } from 'components/ps-chart/models/ConnectionDirection'

/**
 * Gets all named links rules and merge them into unique by name Map<NamedLinkSliceTo/NamedLinkSliceFrom, Slice[]> {@link getFilteredSlicesByLinkName}
 * After that for each type of {@link NamedLinkType} all forEach Map (even for names not included in specific type which I don't think is good idea)
 * is being grouped by specific rule using {@link getSubIndex} except {@link NamedLinkType.SYNC}
 **/
export const fillNamedLinks = (
  sliceById: ReadonlySliceById,
  sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>,
  namedLinksById: Map<string, NamedLinkDto>,
  namedLinks: NamedLinkDto[],
) => {
  const filteredSlicesByLinkName = getFilteredSlicesByLinkName(sliceById, namedLinks)

  const objectNamedLinks: NamedLinkDto[] = []
  const directNamedLinks: NamedLinkDto[] = []
  const complexNamedLinks: NamedLinkDto[] = []
  const restNamedLinks: NamedLinkDto[] = []

  for (const link of namedLinks) {
    namedLinksById.set(link.id, link)
    switch (link.type) {
      case NamedLinkType.OBJECT:
        objectNamedLinks.push(link)
        continue
      case NamedLinkType.DIRECT:
        directNamedLinks.push(link)
        continue
      case NamedLinkType.COMPLEX:
        complexNamedLinks.push(link)
        continue
      default:
        restNamedLinks.push(link)
    }
  }
  console.info(
    'recognized object/direct/complex/rest links',
    objectNamedLinks.length,
    directNamedLinks.length,
    complexNamedLinks.length,
    restNamedLinks.length,
  )

  if (objectNamedLinks.length > 0) {
    const linkNameObjectIdIndex = getSubIndex(filteredSlicesByLinkName, (slice) => slice.objectId)
    for (let i = 0; i < objectNamedLinks.length; i++) {
      const namedLink = objectNamedLinks[i]
      getNamedLinkHandler(sliceById, sliceLinksBySliceId, linkNameObjectIdIndex)(namedLink)
    }
  }

  if (complexNamedLinks.length > 0) {
    const linkNameComplexIdIndex = getSubIndex(filteredSlicesByLinkName, (slice, keyName) => {
      let idType: NamedLinkIdType | undefined = undefined
      for (let i = 0; i < complexNamedLinks.length; i++) {
        if (complexNamedLinks[i].toName.toLowerCase() === keyName) {
          idType = complexNamedLinks[i].toId
        }
        if (complexNamedLinks[i].fromName.toLowerCase() === keyName) {
          idType = complexNamedLinks[i].fromId
        }

        if (idType !== undefined && slice[idType] !== undefined) {
          return `${complexNamedLinks[i].toId}:${complexNamedLinks[i].fromId}:${slice[idType]}`
        }
      }
    })

    for (let i = 0; i < complexNamedLinks.length; i++) {
      const namedLink = complexNamedLinks[i]
      getNamedLinkHandler(sliceById, sliceLinksBySliceId, linkNameComplexIdIndex)(namedLink)
    }
  }

  if (directNamedLinks.length > 0) {
    const linkNamesDirectIdIndex = getSubIndex(
      filteredSlicesByLinkName,
      (slice) => `${slice.threadId}:${slice.level}`,
    )
    for (let i = 0; i < directNamedLinks.length; i++) {
      const namedLink = directNamedLinks[i]
      getNamedLinkHandler(sliceById, sliceLinksBySliceId, linkNamesDirectIdIndex)(namedLink)
    }
  }

  if (restNamedLinks.length > 0) {
    for (let i = 0; i < restNamedLinks.length; i++) {
      const namedLink = restNamedLinks[i]
      getNamedLinkHandler(sliceById, sliceLinksBySliceId, filteredSlicesByLinkName)(namedLink)
    }
  }
}

const addFoundConnections = (
  sliceById: ReadonlySliceById,
  sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>,
  toFromFoundPairs: Map<Slice, Slice[]>,
  sourceId: string,
  isEditable: boolean,
  type: NamedLinkTypeValue,
): number => {
  let addedLinksCount = 0
  for (const [toSlice, fromSlicesList] of toFromFoundPairs.entries()) {
    for (const fromSlice of fromSlicesList) {
      const conError = getConnectionError(fromSlice, toSlice, sliceById, sliceLinksBySliceId, type)
      if (conError == null) {
        const backwardLinks = Array.from(sliceLinksBySliceId.get(fromSlice.id) ?? [])
        backwardLinks.push({
          sourceId,
          fromSliceId: fromSlice.id,
          toSliceId: toSlice.id,
          connectionType: ConnectionType.MANUAL,
          connectionDirection: ConnectionDirection.BACKWARD,
          isEditable,
        })
        sliceLinksBySliceId.set(fromSlice.id, backwardLinks)
        const forwardLinks = Array.from(sliceLinksBySliceId.get(toSlice.id) ?? [])
        forwardLinks.push({
          sourceId,
          fromSliceId: fromSlice.id,
          toSliceId: toSlice.id,
          connectionType: ConnectionType.MANUAL,
          connectionDirection: ConnectionDirection.FORWARD,
          isEditable,
        })
        sliceLinksBySliceId.set(toSlice.id, forwardLinks)
        addedLinksCount++
      }
    }
  }
  return addedLinksCount
}

const addToFromConnection = (
  fromSlice: Slice,
  toSlice: Slice,
  connections: Map<Slice, Slice[]>,
) => {
  const fromSlices = connections.get(toSlice) || []
  fromSlices.push(fromSlice)
  connections.set(toSlice, fromSlices)
}

/**
 * getClosestToFromPairs: is going through two arrays of pairs from named link rule. From the furthest slices to earliest.
 * function is written as it presented to user - in reverse order
 * @toSlices is array of slices where each slice is a caller function
 * @fromSlices is array of slice where each slice is being called by caller function
 */
export const getClosestToFromPairs = (
  fromSlices: Slice[],
  toSlices: Slice[],
  type: NamedLinkTypeValue,
): Map<Slice, Slice[]> => {
  const connections = new Map<Slice, Slice[]>()
  /**
   * Because of the way how we get to fromSlices/toSlices (generated by {@link fillNamedLinks} and then filtered {@link getNamedLinkHandler}).
   * We need to filter out Direct Connection/Link pairs and keep only slices placed directly to each other
   **/
  if (type === NamedLinkType.DIRECT) {
    const directToIds = toSlices.map((slice) => slice.levelPositionIndex ?? -1)
    fromSlices = fromSlices.filter((slice) => {
      const levelPositionIndex = slice.levelPositionIndex ?? -1
      return directToIds.includes(levelPositionIndex - 1)
    })
  }

  let fromPointer = fromSlices.length - 1
  let toPointer = toSlices.length - 1

  const toSliceCopy =
    type === NamedLinkType.ASYNC ? toSlices : toSlices.sort((a, b) => a.end - b.end)

  while (fromPointer >= 0 && toPointer >= 0) {
    const curFromSlice = fromSlices[fromPointer]
    const curToSlice = toSliceCopy[toPointer]
    if (type === NamedLinkType.ASYNC) {
      if (curToSlice.start > curFromSlice.end) {
        toPointer--
        continue
      }
    } else {
      if (curToSlice.start >= curFromSlice.start) {
        toPointer--
        continue
      }
    }
    addToFromConnection(curFromSlice, curToSlice, connections)
    fromPointer--
  }

  return connections
}

export const getFilteredSlicesByLinkName = (
  sliceById: ReadonlySliceById,
  namedLinks: NamedLinkDto[],
): Map<string, Slice[]> => {
  const linksNames = namedLinks.reduce(
    (names, link) => names.add(link.fromName.toLowerCase()).add(link.toName.toLowerCase()),
    new Set<string>(),
  )

  const slicesByTitle = new Map<string, Slice[]>()
  for (const [, slice] of sliceById.entries()) {
    let normalizedTitle = slice.title.toLowerCase()
    normalizedTitle += slice.objectId !== OBJECT_ID_EMPTY_VALUE ? ' @objectId' : ''
    normalizedTitle += slice.runnableId !== OBJECT_ID_EMPTY_VALUE ? ' @runnableId' : ''
    normalizedTitle += slice.futureId !== OBJECT_ID_EMPTY_VALUE ? ' @futureId' : ''

    const slices = slicesByTitle.get(normalizedTitle) ?? []
    slices.push(slice)
    slicesByTitle.set(normalizedTitle, slices)
  }

  /**
   * Complexity:
   * [unique slice names in a trace] X [unique titles in links] + [all slices]
   */
  const filteredSlicesByLinkName = new Map<string, Slice[]>()
  for (const linkName of linksNames) {
    let slicesForLinkName: Slice[] = []
    for (const [slicesTitle, slices] of slicesByTitle.entries()) {
      if (slicesTitle.indexOf(linkName) !== -1) {
        slicesForLinkName = slicesForLinkName.concat(slices)
      }
    }
    filteredSlicesByLinkName.set(linkName, slicesForLinkName)
  }

  for (const [, slices] of filteredSlicesByLinkName.entries()) {
    slices.sort((sliceA, sliceB) => sliceA.start - sliceB.start)
  }

  return filteredSlicesByLinkName
}

export const getSubIndex = <IndexType>(
  slicesByKeyName: Map<string, Slice[]>,
  getSubIndexKey: (slice: Slice, keyName: string) => IndexType,
): Map<string, Map<IndexType, Slice[]>> => {
  const subIndexByKeyName = new Map<string, Map<IndexType, Slice[]>>()
  for (const [keyName, slices] of slicesByKeyName.entries()) {
    const slicesSubIndex = new Map<IndexType, Slice[]>()
    for (const slice of slices) {
      const subIndexKey = getSubIndexKey(slice, keyName)
      const sameSubIndexKeySlices = slicesSubIndex.get(subIndexKey) ?? []
      sameSubIndexKeySlices.push(slice)
      slicesSubIndex.set(subIndexKey, sameSubIndexKeySlices)
    }
    subIndexByKeyName.set(keyName, slicesSubIndex)
  }
  return subIndexByKeyName
}

const getNamedLinkHandler =
  <SubIndexKey>(
    sliceById: ReadonlySliceById,
    sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>,
    slicesIndexByName: Map<string, Map<SubIndexKey, Slice[]> | Slice[]>,
  ) =>
  (sourceNamedLink: NamedLinkDto) => {
    const { id, fromName, toName, type, isEditable } = sourceNamedLink
    const nFromName = fromName.toLowerCase()
    const nToName = toName.toLowerCase()

    const fromSlicesIndex = slicesIndexByName.get(nFromName)
    const toSlicesIndex = slicesIndexByName.get(nToName)

    const logWarning = () =>
      console.warn(
        `Skip the link because can't find it's names: ${id}/${fromName}/${toName}/${type}`,
      )

    if (fromSlicesIndex == null || toSlicesIndex == null) {
      logWarning()
      return null
    } else {
      const fromSlicesIndexSize = Array.isArray(fromSlicesIndex)
        ? fromSlicesIndex.length
        : fromSlicesIndex.size
      const toSlicesIndexSize = Array.isArray(toSlicesIndex)
        ? toSlicesIndex.length
        : toSlicesIndex.size

      if (fromSlicesIndexSize === 0 || toSlicesIndexSize === 0) {
        logWarning()
        return null
      }
    }

    let addedLinksCount = 0
    if (Array.isArray(fromSlicesIndex) && Array.isArray(toSlicesIndex)) {
      addedLinksCount = addFoundConnections(
        sliceById,
        sliceLinksBySliceId,
        getClosestToFromPairs(fromSlicesIndex, toSlicesIndex, type),
        id,
        isEditable,
        type,
      )
    } else if (!Array.isArray(fromSlicesIndex) && !Array.isArray(toSlicesIndex)) {
      for (const [subIndexKey, sameSubIndexKeyFromSlices] of fromSlicesIndex.entries()) {
        const sameSubIndexKeyToSlices = toSlicesIndex.get(subIndexKey)
        if (sameSubIndexKeyToSlices == null || sameSubIndexKeyToSlices.length === 0) {
          continue
        }

        addedLinksCount += addFoundConnections(
          sliceById,
          sliceLinksBySliceId,
          getClosestToFromPairs(sameSubIndexKeyFromSlices, sameSubIndexKeyToSlices, type),
          id,
          isEditable,
          type,
        )
      }
    }
    console.info(
      `Added ${addedLinksCount} named links fromName:${fromName}, toName:${toName}, type:${type}`,
    )
  }
